import { IAuth } from "../interfaces/IAuth";
import api from "../services/api/api";

class AuthAPI {
    validate = async (id: string) => await api.post('/virtualKeyAuthentication',
        {
            autorizacaoId: id,
            service: process.env.REACT_APP_SERVICE_KEY_OUTKEY,
            servicemobile: process.env.REACT_APP_SERVICE_KEY_APP,
        }
    );
}

export default new AuthAPI();