import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none"><circle cx="25" cy="25" r="20" fill="%23b0b0b0" opacity="0.5"/><circle cx="75" cy="75" r="20" fill="%2360a0a0" opacity="0.5"/><rect x="10" y="70" width="15" height="15" fill="%23a0a0a0" opacity="0.5"/><polygon points="80,20 95,35 80,50 65,35" fill="%2360b0b0" opacity="0.5"/></svg>') center/cover no-repeat;
  background-color: rgba(0,0,0,0.7);
    height: 100vh;
  padding: 20px;
    .img
    {
        height: 120px;
    }
    .title
    {
        margin: 0;
        color: #fff;
        font-weight: 550;
        font-size: 18pt;
        margin-top: 30px;
    }
    .subtitle
    {
        text-align: center;
        margin: 0;
        font-size: 9pt;
    }
    .content
    {
        margin: 0;
        color: rgba(255,255,255,0.7);
        font-size: 12pt;
    }

    .return
    {
        padding: 17px;
        background-color: #b89cf3;
        text-align: center;
        width: 100%;
        margin-top: 60px;
        font-weight: bold;
        border-radius: 11px;
        box-shadow: 1px 2px 4px rgba(0,0,0,0.2);
        color: #fff;
        font-size: 12pt;
    }
`;