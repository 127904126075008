import { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { jwtDecode } from "jwt-decode";

// pages
import HomePage from '../pages/home';
import TriggerPage from '../pages/trigger';
import TutorialPage from '../pages/tutorial';
import ValidationErroPage from '../pages/validationerro';

// interface route private
interface IPrivate {
  children: any;
}

const isAuthenticated = () => {
  const token = localStorage.getItem("@token_authorization");

  if (token) {
    const decodedToken: { exp: number } = jwtDecode(token);
    const currentTime = Math.floor(Date.now() / 1000);
    const tokenExpirationTime = decodedToken.exp + 10800;

    if (tokenExpirationTime < currentTime) {
      localStorage.setItem('@refresh_data', "0x53")
      return true;
    } else {
      return true;
    }
  }
}


export const PrivateRoute: FC<IPrivate> = ({ children }) => {
  return isAuthenticated() ? children : <Navigate to={'/login'} />
}

const Router = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path={'/chavevirtual/login/:id'} element={<HomePage />} />
        <Route path={'/notoken'} element={<ValidationErroPage />} />
        <Route path={'/acionamentos'} element={<TriggerPage />} />
        <Route path={'/tutorial'} element={<TutorialPage />} />
      </Routes>
    </BrowserRouter>
  )
}

export default Router;