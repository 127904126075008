import { observer } from 'mobx-react-lite';
import { ButtonBottom, Container, SubTitleContent, Title, TitleContent } from './styles/index.style';
import HomeImg from '../../assets/svgs/home.svg';
import AnimatedText from '../../components/animatedtext';
import { useContext, useEffect, useState } from 'react';
import { StoresContext } from '../../stores';
import toast from 'react-hot-toast';
import { useNavigate } from 'react-router-dom';

const HomePage = () => {

    const navigate = useNavigate();
    const { authorizationStore } = useContext(StoresContext);
    const [transition, setTransition] = useState(false);

    useEffect(() => {
        if (window.location.pathname.split('/')[3] !== null) {
            var tokenId = window.location.pathname.split('/')[3];
            Promise.all([
                authorizationStore.validateAuthorizedById(tokenId)
            ])
                .then((res) => {
                    setTimeout(() => navigate('/acionamentos'), 1500);
                })
                .catch(() => {
                    window.location.replace("/notoken");
                    toast.error("Token inválido");
                });
        }
    }, []);




    return (
        <Container>
            <div>
                <Title>OUTKEY</Title>
                <div className='d-flex justify-content-center'>
                    <img src={HomeImg} className='img' />
                </div>
                <div className='d-flex justify-content-center'>
                    <TitleContent>
                        <AnimatedText
                            phrases={[
                                'Olá, esta é a sua chave virtual',
                                'Estamos validando seu acesso. Por favor, aguarde!',
                            ]}
                        />
                    </TitleContent>
                </div>
                <div className='d-flex justify-content-center'>
                    <SubTitleContent>
                        A chave virtual é uma maneira segura, prática e ágil de liberar
                        acessos;
                    </SubTitleContent>
                </div>
                <div className='d-flex justify-content-center'>
                    <ButtonBottom>
                        <div className="loader"></div>
                    </ButtonBottom>
                </div>
            </div>
        </Container>
    )
}

export default observer(HomePage);
