import styled from 'styled-components';
import PerfectScrollbar from 'react-perfect-scrollbar';
import background from '../../../assets/images/background-loading.png';

export const Container = styled.div`
    height: 70px;
    background: #d7d7d7;
    width: auto;
    border-radius: 60px;
    border: 1px solid rgba(0,77,147,0.1);
    box-shadow: 1px 3px 7px rgba(0,0,0,0.2);
    .div-check
    {
        background: rgba(0,0,0,0.3);
        height: 68px;
        width: 68px;
        border-radius: 70px;
        margin-left: 2px;
    }
    .icon-right
    {
        align-self: center;
        color: rgba(0,77,147,0.3);
        font-size: 20pt;
    }
    .icon-check
    {
        align-self: center;
        color: #fff;
        font-size: 24pt;
    }
    .icon-success
    {
        align-self: center;
        color: green;
        font-size: 25pt;
    }
    .icon-error
    {
        align-self: center;
        color: red;
        font-size: 27pt;
    }
    .title
    {
    }
    .loader
    {
        align-self: center;
    }
`;

export const Acionamento = styled.p`
    padding-top: 5px;
    padding-bottom: 15px;
    margin-left: 35px;
    .icon
    {
        color: rgba(0,77,147,0.7);
        font-size: 19pt;
        margin: 0;
        margin-right: 10px;
        align-self: center;
    }
    .title
    {
        color: rgba(0,77,147,0.7);
        font-size: 9pt;
        align-self: center;
        margin: 0;
        padding-left: 5px;
    }
`;

export const Title = styled.p`
    color: rgba(0,77,147,0.5);
    text-align: center;
    font-size: 8pt;
    padding-top: 2px;
    margin-top: 5px;
`;