import { observer } from 'mobx-react-lite';
import React, { useRef, useState, useContext, FC } from 'react'
import { Modal } from 'rsuite';
import background from '../../assets/images/location.png';
import { Container } from './styles/component.style';
import { StoresContext } from '../../stores';
import { MdOutlineNotificationsActive } from 'react-icons/md';
import { BsX } from 'react-icons/bs';
import { useNavigate } from 'react-router-dom';


const TutorialPage = () => {

  const navigate = useNavigate();

  const handleReturn = () =>
  {
    navigate('/acionamentos')
  }

  const RenderAccepetedLocation = () => {
    return (
      <Container>
        <div className='d-flex justify-content-center'>
          <img src={background} className='img' />
        </div>
        <p className='title'>Habilite a localização</p>
        <p className='subtitle'>Para a devida liberação do acionamento, é necessário que a funcionalidade de localização esteja ativada</p>
        <p className='info'>A liberação dos acionamentos ocorrerá imediatamente após a habilitação da sua localização.</p>
      </Container>
    )
  }

  const RenderNotAccepetedLocation = () => {
    return (
      <Container>
        <div className='d-flex justify-content-center'>
          <img src={background} className='img' />
        </div>
        <p className='title'>Ops!</p>
        <p className='subtitle'>Infelizmente constatamos que a funcionalidade de localização está desabilitada, o que pode prejudicar a sua experiência como usuário :(</p>
        <p className='info'>
          Para que os acionamentos possam estar disponíveis para você, é necessário acessar as configurações e habilitar a função de localização. <br />
          Em seguida, atualize a página para que as alterações sejam efetuadas com sucesso.
        </p>
      </Container>
    )
  }

  return (
    <Container>
      <div className='d-flex justify-content-end' style={{ marginBottom: '-50px' }}>
        {/* <BsX style={{ fontSize: 35 }} onClick={hide} /> */}
      </div>
      <br />
      <br />
      <br />
      <div>
        <div>
          <p className='title'>Recado do morador para o visitante</p>
          <p className='content'>
            Junto ao ícone <MdOutlineNotificationsActive />, encontra-se a mensagem que o morador deseja comunicar ao visitante. Tal recurso possibilita ao morador uma
            comunicação clara e ágil com o visitante, contribuindo para uma experiência de visita segura e confortável.
          </p>
        </div>


        <div className='mt-4'>
          <p className='title'>Como liberar um acesso?</p>
          <p className='content'>
            Para efetuar um acionamento, é necessário segurar o botão indicado por uma seta apontando para a direita e arrastá-lo até o final, a fim de liberar o portão com sucesso. O acionamento será efetuado após o icone de checkin ser exibido. Recomenda-se seguir cuidadosamente essas instruções para garantir
            que o processo de abertura seja realizado adequadamente.
          </p>
        </div>
      </div>

      <button className='return' onClick={handleReturn}>
        Voltar
      </button>
    </Container>
  )
}

export default observer(TutorialPage);
