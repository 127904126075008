import React, { useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoresContext } from './stores';
import { CustomProvider } from 'rsuite';
import { ThemeProvider } from 'styled-components';
import GlobalStyles from './styles/globalStyles';
import dark from './styles/themes/dark';
import light from './styles/themes/light';
import { Toaster } from 'react-hot-toast';
import Router from './routes';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'rsuite/dist/rsuite.min.css';

const App = () => {

  const { themeStore } = useContext(StoresContext);


  return (
    <CustomProvider theme={themeStore.theme === 'dark' ? 'dark' : 'light'}>
      <ThemeProvider theme={(themeStore.theme === 'dark') ? dark : light}>
        <Toaster />
        <GlobalStyles />
        <Router/>
      </ThemeProvider>
    </CustomProvider>
  );
}

export default observer(App);