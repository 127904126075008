import { observer } from 'mobx-react-lite';
import { ButtonBottom, Container, SubTitleContent, Title, TitleContent } from './styles/index.style';
import NoToken from '../../assets/svgs/nototken.svg';

const ValidationErroPage = () => {
    return (
        <Container>
            <div>
                <Title>OUTKEY</Title>
                <div className='d-flex justify-content-center'>
                    <img src={NoToken} className='img' />
                </div>
                <div className='d-flex justify-content-center'>
                    <TitleContent>
                        Chave inválida
                    </TitleContent>
                </div>
                <div className='d-flex justify-content-center'>
                    <SubTitleContent>
                        Ops! Parece que o token da sua chave virtual não está mais disponível. Isso pode ocorrer por diversos motivos, como expiração do token e
                        problemas de sincronização. Por favor, verifique a validade da sua chave virtual.
                    </SubTitleContent>
                </div>

            </div>
        </Container>
    )
}

export default observer(ValidationErroPage);
