import styled from 'styled-components';

export const Container = styled.div`
  height: 100vh; 
  background-color: #b89cf3;
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none"><circle cx="25" cy="25" r="20" fill="%23b0b0b0" opacity="0.5"/><circle cx="75" cy="75" r="20" fill="%2360a0a0" opacity="0.5"/><rect x="10" y="70" width="15" height="15" fill="%23a0a0a0" opacity="0.5"/><polygon points="80,20 95,35 80,50 65,35" fill="%2360b0b0" opacity="0.5"/></svg>') center/cover no-repeat;
`;

export const Content = styled.div`
  padding: 20px;
  background-color: whitesmoke;
  margin-bottom: 7px;
  .name
  {
    font-size: 11pt;
    font-weight: 600;
    margin: 0;
    color: rgba(0,0,0,0.6);
  }
  .qtd
  {
    font-size: 10pt;
    margin: 0;
    color: rgba(0,0,0,0.7);
    margin-bottom: 7px;
  }
`;

export const Header = styled.div`
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none"><circle cx="25" cy="25" r="20" fill="%23b0b0b0" opacity="0.5"/><circle cx="75" cy="75" r="20" fill="%2360a0a0" opacity="0.5"/><rect x="10" y="70" width="15" height="15" fill="%23a0a0a0" opacity="0.5"/><polygon points="80,20 95,35 80,50 65,35" fill="%2360b0b0" opacity="0.5"/></svg>') center/cover no-repeat;
  background-color: rgba(0,0,0,0.5);
  padding: 10px 0 30px 20px;
  border-bottom-left-radius: 17px;
  border-bottom-right-radius: 17px;
  margin-bottom: 10px;
  box-shadow: 1px 2px 4px rgba(0,0,0,0.5);
  .avatar
  {
    height: 50px;
    width: 50px;
    margin-right: 10px;
    align-self: center;
  }
  .title
  {
    font-size: 15pt;
    font-weight: 600;
    color: #fff;
    margin: 0;
  }
  .subtitle
  {
    font-size: 10pt;
    color: #fff;
    margin: 0;
  }
  .card-icon
  {
    height: 50px;
    width: 50px;
    background-color: rgba(255,255,255,0.5);
    box-shadow: 1px 2px 4px rgba(0,0,0,0.2);
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 60px;
  }
  .icon
  {
    font-size: 22pt;
  }
  .card-item-info
  {
    margin-top: 20px;
  }
  .title-header
  {
    color: #fff;
    margin: 0;
    font-size: 11pt;
    font-weight: bold;
  }
  .subtitle-header
  {
    color: #fff;
    margin: 0;
    font-size: 10pt;
  }
`;