// AnimatedText.tsx
import React, { useEffect, useState } from 'react';
import { Text } from './styles/index.style';

interface AnimatedTextProps {
  phrases: string[];
}

const AnimatedText: React.FC<AnimatedTextProps> = ({ phrases }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) =>
        prevIndex === phrases.length - 1 ? 0 : prevIndex + 1
      );
    }, 4000);

    return () => clearInterval(interval);
  }, [phrases.length]);

  return (
    <div>
      <Text key={currentIndex}>{phrases[currentIndex]}</Text>
    </div>
  );
};

export default AnimatedText;
