export default {
  title: 'light',
  layout: {
    menu: {
      width: 80,
      bgColor: '#242644',
    },
  },
  colors: {
    default: '#fff',
    avatar: '#242644',
    primary: '#5448c8',
    success: 'green',
    danger: '#FE193F',
    warning: '#f8961e',
    text: '#333',
    bgColor: '#F5F7FB',
    bgBox: '#fff',
    borderColor: '#dee2e6',
    nota: '#E6E6FA',
  },
};
