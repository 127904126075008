import { makeAutoObservable } from "mobx";

interface Coordinates {
    latitude: number;
    longitude: number;
}

class GeoLocationStore {

    accepted: boolean = false;
    error: boolean = false;
    latitude: number = 0;
    longitude: number = 0;
    message: string = "";

    constructor() {
        this.accepted = false;
        makeAutoObservable(this);
    }

    getGeoLocation = (): Promise<Coordinates> =>
        new Promise((resolve, reject) => {
        this.accepted = false;
        if (navigator.permissions) {
            navigator.permissions.query({ name: "geolocation" }).then((permissionStatus) => {
                if (permissionStatus.state === "granted") {
                    navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.accepted = true;
                        const { latitude, longitude } = position.coords;
                        this.latitude = latitude;
                        this.longitude = longitude;
                        this.error = false;
                    },
                    (error) =>
                    {
                        this.accepted = false
                        this.error = true;
                    }
                );
                } else if (permissionStatus.state === "prompt") {
                    this.message = "prompt";
                    this.error = false;
                    navigator.geolocation.getCurrentPosition(
                    (position) => {
                        this.accepted = true;
                        const { latitude, longitude } = position.coords;
                        this.latitude = latitude;
                        this.longitude = longitude;
                    resolve({ latitude, longitude });
                    },
                    (error) => 
                    {
                        this.accepted = false;
                        this.message = "erro";
                    }
                );
                } else {
                this.error = true;
                this.accepted = false;
                this.message = "Permissão para geolocalização negada";
            }
        });
        } else {
            this.accepted = false
            this.message = "Permissões não suportada para o seu aparelho";
            reject(new Error("API de permissões não suportada"));
        }
    });

    requestLocation = () => {
        if (navigator.geolocation) {
          navigator.geolocation.getCurrentPosition(function(position) {
          });
        } else {
            this.accepted = false
            this.message = "Geolocalização não suportada pelo navegador";
        }
    }
}

export default GeoLocationStore;