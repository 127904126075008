import { createContext } from 'react';
import ThemeStore from './ThemeStore';
import TriggerStore from './TriggerStore';
import AuthorizationStore from './AuthorizationStore';
import GeoLocationStore from './GeoLocationStore';

export const StoresContext = createContext({
  themeStore: new ThemeStore(),
  triggerStore: new TriggerStore(),
  authorizationStore: new AuthorizationStore(),
  geoLocationStore: new GeoLocationStore(),
});
