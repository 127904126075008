import { createGlobalStyle } from 'styled-components';
import { lighten, darken } from 'polished';

const fontSize = localStorage.getItem('fontSize')

export const GlobalStyles = createGlobalStyle`
  body{
    /* user-select: none; */
    font-size: ${fontSize ? fontSize : 12}px;
    color: ${(props) => props.theme.colors.text};
    font-family: Arial, sans-serif;
    font-weight: 50;
    text-decoration: none;
    margin: 0;
    padding: 0;
    // position: fixed;
    // width: 100%;
  }

  ::-webkit-scrollbar {
    display: none;
  }
  
  .background-icon-button
  {
    background: ${(props) => props.theme.colors.primary};
  }

  .icon-button
  {
    color: ${(props) => props.theme.colors.primary};
  }

  .modal-body
  {
    &::-webkit-scrollbar {
      width: 10px;
      border: 0;
    }
  }

  #input-responsive
  {
    background-color: ${(props) => props.theme.colors.bgColor};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid ${(props) => props.theme.colors.borderColor};
  }

  .form-group label{
    text-transform: uppercase;
    font-size: 12px;
    letter-spacing: 0.5px;
    font-weight: 700;
  }

  .form-control:focus, .btn:focus{
    outline:0;
    -webkit-box-shadow:none;
    box-shadow:none;
  }

  // Dropdown
  .dropdown {
    .dropdown-toggle {
      &:after{
        display: none;
      }
    }

    .dropdown-menu{
      border-color: rgba(0, 0, 0, 0.1);
      background-color: ${(props) => props.theme.colors.default};
      box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.07);

      .dropdown-item {
        border-color: rgba(0, 0, 0, 0.1);
        color: ${(props) => props.theme.colors.text};

        &:hover{
          background-color: transparent;
        }

        &:last-child {
          border-bottom: 0;
        }
      }
    }
  }

  // Modal
  .modal {
    background: rgba(0, 0, 0, 0.7);

    .modal-dialog {
      .modal-content {
        border-radius: 3px;
        overflow: hidden;
        border: 0;
        box-shadow: 3px 3px 14px rgba(0, 0, 0, 0.14);
        background-color: ${(props) => props.theme.colors.default};
      }
      .modal-header{
        border-bottom: 0;
        font-size: 20px;
        font-weight: 700;
        align-items: center;

        .close{
          font-size: 2rem;
          font-weight: 400;
          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  // Buttons
  .btn {
    letter-spacing: 0.5px;
    font-weight: 600;

    &.btn-primary {
      background-color: ${(props) => props.theme.colors.primary};
      border-color: ${(props) => props.theme.colors.primary};
    }

    // Outlines
    &.btn-outline-primary {
      border-color: ${(props) => props.theme.colors.primary};
      color: ${(props) => props.theme.colors.primary};

      &:hover {
        background-color: ${(props) => props.theme.colors.primary};
        color: #fff;
      }
    }
  }

  // Cards
  .card {
    background-color: ${(props) => props.theme.colors.default};
    border-radius: 8px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .card-header {
      font-weight: 600;
      font-size: 16px;
      color: #555;
      background-color: transparent;
      border-bottom: 0;

      .card-header-pills {
        .nav-link {
          border: transparent;
          color: ${(props) => props.theme.colors.primary};
          font-size: 18px;
          padding: 0 6px;
          background-color: transparent;
          margin-left: 3px;
        }
      }
    }
  }

  // List group
  .list-group {
    .list-group-item {
      background-color: transparent;
      color: ${(props) => props.theme.colors.text};
      padding-top: 8px;
      padding-bottom: 8px;
      transition: all .4s;
      /* border-bottom: 1px solid ${(props) => lighten(0.74, props.theme.colors.text)}; */
      border-bottom: 1px solid rgba(0, 0, 0, 0.15);

      &:hover{
        background-color: ${(props) => darken(0.05, props.theme.colors.default)};
      }
    }
  }

  // Borders
  .border, .border-top, .border-bottom, .border-left, .border-right {
    border-color: ${(props) => props.theme.colors.borderColor} !important;
  }

  // Tabs
  .nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    background-color: ${(props) => props.theme.colors.bgColor};
    color: ${(props) => props.theme.colors.text};
    border: 1px solid transparent;
    font-weight: 600;
    border-radius: 10px;
  }

  .nav-tabs {
      border-bottom: none;
      margin: 2px;
  }

  .nav-tabs .nav-link{
    border: 1px solid transparent;
    color: gray;
  }

  .nav-tabs .nav-link:hover{
    border: 1px solid transparent;
    color: ${(props) => props.theme.colors.text};
  }

  .nav-link:active {
    color: ${(props) => props.theme.colors.text};
    border: 0;
  }


  // Tables
  .table {
    color: ${(props) => props.theme.colors.text};

    td, th {
      border-bottom: 1px solid ${(props) => props.theme.colors.borderColor};
    }

    thead {
      th {
        border-top: 1px solid ${(props) => props.theme.colors.borderColor};
        border-bottom: 2px solid ${(props) => props.theme.colors.borderColor};
      }
    }
  }

  // Background-colors
  .bg-primary {
    background-color: ${(props) => props.theme.colors.primary} !important;
  }

  .disabled-text-decoration
  {
    color: red;
  }

`;

export default GlobalStyles;
