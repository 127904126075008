import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
  height: 100vh; /* Ocupa 100% da altura da tela */
  background: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 100 100" fill="none"><circle cx="25" cy="25" r="20" fill="%23b0b0b0" opacity="0.5"/><circle cx="75" cy="75" r="20" fill="%2360a0a0" opacity="0.5"/><rect x="10" y="70" width="15" height="15" fill="%23a0a0a0" opacity="0.5"/><polygon points="80,20 95,35 80,50 65,35" fill="%2360b0b0" opacity="0.5"/></svg>') center/cover no-repeat;

  .img
  {
    height: 300px;
    margin-top: 10%;
  }
`;
export const Title = styled.p`
  text-align: center;
  font-size: 10pt;
  color: rgba(0,0,0,0.6);
  font-weight: 900;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  letter-spacing: 2px;
`;

export const TitleContent = styled.p`
  text-align: center;
  font-size: 26pt;
  color: rgba(0,0,0,0.8);
  font-weight: 600;
  width: 90%;
  align-self: center;
  line-height: 40px;
  margin-bottom: 10px;
`;

export const SubTitleContent = styled.p`
  text-align: center;
  font-size: 13pt;
  color: rgba(0,0,0,0.6);
  font-weight: 900;
  font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
  width: 70%;
  line-height: 20px;
`;


export const ButtonBottom = styled.div`

margin-top: 40px;

.loader {
  width: 50px;
  aspect-ratio: 1;
  display: grid;
  border: 4px solid #0000;
  border-radius: 50%;
  border-right-color: #7239ea;
  animation: l15 1s infinite linear;
}
.loader::before,
.loader::after {    
  content: "";
  grid-area: 1/1;
  margin: 2px;
  border: inherit;
  border-radius: 50%;
  animation: l15 2s infinite;
}
.loader::after {
  margin: 8px;
  animation-duration: 3s;
}
@keyframes l15{ 
  100%{transform: rotate(1turn)}
}
`;



/* background-color: ${(props) => props.theme.colors.bgColor}; */