// AnimatedTextStyles.ts
import styled, { keyframes } from 'styled-components';

const fadeInOut = keyframes`
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
`;

export const Container = styled.span`

`;

export const Text = styled.span`
  animation: ${fadeInOut} 4s infinite;
`;
