import axios, { AxiosResponse } from 'axios';

const baseURL = process.env.REACT_APP_BASE_MS_AUTHENTICATION;

var api = axios.create({ 
  baseURL : baseURL,
  headers: {
    "Content-Type": "application/json",
  }
});

api.interceptors.request.use(
  (config) => {
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default api;