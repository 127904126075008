import React, { useContext, useRef, useState } from 'react'
import { Container } from './styles/index.style';
import { useSpring, animated } from "react-spring";
import { observer } from 'mobx-react-lite';
import { BsCheck2, BsCheck2All, BsChevronDoubleRight, BsDoorOpen, BsKey } from 'react-icons/bs';
import { StoresContext } from '../../stores';
import { EStatus } from '../../enums/EStatus';
import { MdErrorOutline } from 'react-icons/md';
import { Loader } from 'rsuite';
import { useTrigger } from '../../hooks/trigger.hook';

interface Props {
    id: string;
}

const Trigger = ({ id }: Props) => {

    const { triggerStore, geoLocationStore, authorizationStore } = useContext(StoresContext);
    const { trigger, loading } = useTrigger();
    const [isPressed, setIsPressed] = useState(false);
    const [isPressedButton, setIsPressedButton] = useState(false);
    const [disabled, setDisabled] = useState(false);
    const [status, setStatus] = useState<EStatus>(EStatus.default);
    const containerTrigger = useRef<HTMLDivElement>(null);
    const buttonWidth = 70;

    const [{ x }, set] = useSpring<{ x: number }>(() => ({
        x: 0,
        config: { mass: 0.1, tension: 200, friction: 10 },
        onFrame: ({ x }: { x: number }) => {
            set({ x });
        },
        onRest: (finalPosition) => {
            const containerWidth = containerTrigger.current?.offsetWidth ?? 1 - buttonWidth
            const valueEnd = finalPosition.value.x as number + buttonWidth;

            if (valueEnd < containerWidth) {
                set({ x: 0 });
            }
            else {
                set({ x: finalPosition.value.x as number });
            }
        }
    }));

    const handleTouchStart = (e: React.TouchEvent<HTMLDivElement>) => {
        setIsPressed(true);
        handleTouchMove(e);
    };

    const handleTouchEnd = () => {
        setIsPressed(false);
    };

    const handleTouchMove = (e: React.TouchEvent<HTMLDivElement>) => {
        if (!isPressed && !disabled || authorizationStore.visitor?.quantidade === 0) {
            return;
        }

        const buttonWidth = 70;
        const parentWidth = (e.currentTarget as HTMLDivElement).offsetWidth;
        const parentWidth2 = (e.currentTarget as HTMLDivElement).offsetWidth - buttonWidth;
        const newX =
            e.touches[0].clientX -
            (e.currentTarget as HTMLDivElement).offsetLeft -
            buttonWidth / 2;

        set({ x: newX });
        if (newX < 0) {
            set({ x: 0 });
        }
        if (
            newX > (parentWidth - buttonWidth)
            &&
            !disabled && triggerStore.selected &&
            authorizationStore.visitor &&
            geoLocationStore.accepted &&
            geoLocationStore.latitude !== 0 &&
            geoLocationStore.longitude !== 0
        ) {
            setDisabled(true);
            setIsPressed(false);
            set({ x: parentWidth2 });
            setStatus(EStatus.loadingTriggerTrue)
            var payload =
            {
                acionamentoId: triggerStore.selected,
                visitanteId: authorizationStore.visitor.visitanteId,
                latitude: geoLocationStore.latitude,
                longitude: geoLocationStore.longitude
            }
            trigger(payload)
                .then((res) => {
                    if (res === 'sucesso') setStatus(EStatus.success)
                    else setStatus(EStatus.error)
                    setTimeout(() => {
                        setStatus(EStatus.default)
                        set({ x: 0 });
                        setDisabled(false);
                    }, 2000);
                })
                .catch(() => {
                    setStatus(EStatus.error);
                    set({ x: 0 });
                    setStatus(EStatus.loadingTriggerFalse)
                    setDisabled(false);
                })
        }
        else if (newX < parentWidth2 && disabled) {
            set({ x: 0 });
        }
        else if (newX > parentWidth - buttonWidth) {
            set({ x: parentWidth - buttonWidth });
        }
    };


    const renderAction = (action: EStatus, select?: string) => {
        switch (action) {
            case EStatus.success:
                return triggerStore.selected === select ? <BsCheck2All className='icon-success' /> : <BsChevronDoubleRight className='icon-right' />;
            case EStatus.error:
                return triggerStore.selected === select ? <MdErrorOutline className='icon-error' /> : <BsChevronDoubleRight className='icon-right' />;
            case EStatus.loadingTriggerTrue:
                return triggerStore.selected === select ? <Loader className='loader' size={'lg'} /> : <BsChevronDoubleRight className='icon-right' />;
            case EStatus.loadingTriggerFalse:
                return <BsChevronDoubleRight className='icon-right' />;
            case EStatus.default:
                return <BsChevronDoubleRight className='icon-right' />;
        }

        return <div>Erro...</div>;
    }

    return (
        <Container
            ref={containerTrigger}
            onTouchStartCapture={() => {
                triggerStore.setSelected(id);
            }
            }
            onTouchMove={!disabled || !loading ? handleTouchMove : undefined}
            className='d-flex justify-content-between'
            style={{
                background: authorizationStore.visitor?.quantidade === 0 ? 'rgba(0,0,0,0.5)' : ''
            }}
        >
            <animated.div
                style={{
                    transform: triggerStore.selected === id ? x.to((x) => `translateX(${x}px)`) : `translateX(0px)`,
                    height: "100%",
                    width: "70px",
                    backgroundColor: "#fff",
                    cursor: "pointer",
                    borderRadius: 60,
                    border: '2px solid #b89cf3',
                }}
                onTouchStartCapture={(res) => {
                    setIsPressedButton(true);
                    triggerStore.isPressed = true;
                }
                }
                onTouchEndCapture={() => {
                    setIsPressedButton(false);
                    triggerStore.isPressed = false;
                }}
                onTouchStart={!disabled || !loading ? handleTouchStart : undefined}
                onTouchEnd={!disabled || !loading ? handleTouchEnd : undefined}
                className={'d-flex justify-content-center'}
            >
                {
                    renderAction(status, id)
                }
            </animated.div>
            {
                authorizationStore.visitor?.quantidade === 0
                    ?
                    <div className='d-flex align-self-center'>
                        <div>
                            <p style={{textAlign: 'center', margin: 0, fontSize: 15, fontWeight: 'bold', color: 'rgba(255,255,255,0.8)'}}>Acionamento indisponível</p>
                            <p style={{textAlign: 'center', margin: 0, fontSize: 13, color: 'rgba(255,255,255,0.6)'}}>Você já atingiu a quantidade dispobilizada</p>
                        </div>
                    </div>
                    :
                    <div className='d-flex align-self-center'>
                        <BsChevronDoubleRight style={{ color: '#fff', fontSize: 20, marginLeft: '-7px' }} />
                        <BsChevronDoubleRight style={{ color: '#fff', fontSize: 20, marginLeft: '-7px' }} />
                        <BsChevronDoubleRight style={{ color: '#fff', fontSize: 20, marginLeft: '-7px' }} />
                    </div>

            }

            <div className='div-check d-flex justify-content-center'>
                <BsCheck2 className='icon-check' />
            </div>
        </Container>
    )
}

export default observer(Trigger);
