import { observer } from 'mobx-react-lite';
import { Container, Content, Header } from './styles/index.style';
import Trigger from '../../components/trigger';
import { Avatar } from 'rsuite';
import { LuScanFace } from "react-icons/lu";
import { TfiHelpAlt } from "react-icons/tfi";
import { useNavigate } from 'react-router-dom';
import { useContext, useEffect } from 'react';
import { StoresContext } from '../../stores';
import ModalInfoGeoLocation from '../../components/modals/infogeolocation';


const TriggerPage = () => {

    const { authorizationStore, geoLocationStore } = useContext(StoresContext);
    const navigate = useNavigate();

    useEffect(() => {
        if (authorizationStore.validated)
            geoLocationStore.getGeoLocation();
    }, [authorizationStore.validated]);

    useEffect(() => {
        const isPageReloaded = sessionStorage.getItem('pageReloaded');
        if (!isPageReloaded) {
            sessionStorage.setItem('pageReloaded', 'true');
        } else {
            const id = localStorage.getItem("@token_authorization");
            if (id) navigate(`/chavevirtual/login/${id}`);
        }

        return () => {
            sessionStorage.removeItem('pageReloaded');
        };
    }, [navigate]);

    const handleHelp = () => {
        navigate('/tutorial');
    }

    const handleFace = () => {
        navigate('/cadastro-facialtutorial');
    }


    return (
        <Container>
            <Header>
                <div className='d-flex mb-1 mt-3'>
                    <Avatar circle className='avatar' />
                    <div>
                        <p className='title'>Olá, {authorizationStore.visitor?.nome}</p>
                        <p className='subtitle'>
                            <strong>Recado:</strong>
                            {authorizationStore.visitor?.recado || "Sem recado"}
                        </p>
                    </div>
                </div>
                <div className='d-flex'>
                    <div style={{}}>
                        {/* <div className='card-item-info d-flex' style={{ display: authorizationStore.visitor?.facial ? 'block' : 'block' }} onClick={handleFace}>
                            <div className='card-icon'>
                                <LuScanFace className='icon' />
                            </div>
                            <div>
                                <p className='title-header'>Cadastro facial</p>
                                <p className='subtitle-header'>Cadastre seu rosto nos dispositivos</p>
                            </div>
                        </div> */}
                        <div className='card-item-info d-flex' onClick={handleHelp}>
                            <div className='card-icon'>
                                <TfiHelpAlt className='icon' />
                            </div>
                            <div className='align-self-center'>
                                <p className='title-header'>Precisa de ajuda?</p>
                                <p className='subtitle-header'>Tire suas dúvidas aqui</p>
                            </div>
                        </div>
                    </div>
                </div>

            </Header>
            {
                authorizationStore.visitor?.acionamentosList.map((x, i) =>
                    <Content key={i} >
                        <p className='name'>{x.Nome}</p>
                        <p className='qtd'>Quantidade de acionamentos: {authorizationStore.visitor?.quantidade}</p>
                        <Trigger id={x.Id} />
                    </Content>)
            }


            {
                !geoLocationStore.accepted
                    ?
                    <ModalInfoGeoLocation show={!geoLocationStore.accepted} hide={() => { }} />
                    :
                    ''
            }
        </Container>
    )
}

export default observer(TriggerPage);
