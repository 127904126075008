import { makeAutoObservable } from "mobx";

class TriggerStore {

    selected: string = '';
    loading: boolean = false;
    expired: boolean = false;
    isPressed: boolean = false;

    constructor() {
        makeAutoObservable(this);
    }

    async setSelected(id: string) {
        if(id)
            this.selected = id;
        else
            this.selected = '';
    }
}

export default TriggerStore;